@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy/Gilroy-Regular.ttf");
}
@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy/Gilroy-Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy/Gilroy-RegularItalic.ttf");
    font-style: italic;
}
@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy/Gilroy-BoldItalic.ttf");
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy/Gilroy-MediumItalic.ttf");
    font-style: oblique;
}
@font-face {
    font-family: "Gilroy";
    src: url("./fonts/Gilroy/Gilroy-BoldItalic.ttf");
    font-weight: bold;
    font-style: oblique;
}